import React, { Component } from "react";
import CommercialImage from "assets/images/svg/Ilustracje_nowe_5.svg";
import { Button } from "react-bootstrap";
import CommercialForm from "../Form/CommercialForm";
import SkyImage from "../../assets/images/svg/latest/Ilustracje_4_Sky_grouped.svg";
import { OrangeButton } from "../Buttons";
import LottieAnimation from "components/Animations/animations";

export default class CommercialAndPublic extends Component {
  constructor() {
    super();

    this.state = {
      form: false,
    };

    // Turned off because it confuses the logic
    // this.hideShowForm = this.hideShowForm.bind(this);
  }

  // Turned off because it confuses the logic
  // before changes this was used in "book now" btn
  // hideShowForm() {
  //   this.setState({
  //     form: !this.state.form,
  //   });
  // }
  render() {
    return (
      <>
        <section className="d-flex flex-column justify-content-center commercial-and-public-section py-5">
          <div className="row d-flex flex-column flex-lg-row justify-items-center">
            <div className="col-12 col-lg-6 text-center">
              <LottieAnimation animationName={'statue'} />
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column sky-text justify-content-center">
              <h1>
                Commercial and public <br /> space care
              </h1>
              <p>
                Our maintenance service doesn’t just deal in high-quality
                personal gardens, we also have several industrial and commercial
                contacts. Our experienced gardeners are able to
                keep an exceptional standard of work at all times, making sure your
                business appears presentable and professional. Whether it be
                overgrown hedges, or litter, we are able to tailor our services to your site.
                Also, we have the ability to remove any green waste from premises if
                no suitable disposal facilities are on your premises.
              </p>
              <OrangeButton
                title="book now"
                onClick={this.props.goToContactSection}
                className={
                  this.state.form
                    ? "invisible"
                    : "align-self-center align-self-lg-end"
                }
              />
            </div>
          </div>
        </section>
        <div>
          <CommercialForm hide={!this.state.form} />
        </div>
      </>
    );
  }
}
