import React, { Component } from "react";
import { Button } from "react-bootstrap";
import EcoBasicForm from "../Form/EcoBasicForm";
import EcoIntermediateForm from "../Form/EcoIntermediateForm";
import EcoAdvancedForm from "../Form/EcoAdvancedForm";

import LottieAnimation from "components/Animations/animations";

export default class EarthEcoPackage extends Component {
  constructor() {
    super();

    this.state = {
      hidden: true,
      firstBigCardExpanded: false,
      secondBigCardExpanded: false,
      thirdBigCardExpanded: false,
      basicForm: false,
      intermediateForm: false,
      advancedForm: false,

      propsForDisplayBasic: false,
    };

    this.contentRef = React.createRef();

    this.hideShowContent = this.hideShowContent.bind(this);
    this.expandFirstBigCard = this.expandFirstBigCard.bind(this);
    this.expandSecondBigCard = this.expandSecondBigCard.bind(this);
    this.expandThirdBigCard = this.expandThirdBigCard.bind(this);
    this.takeMeBackToAllPackages = this.takeMeBackToAllPackages.bind(this);
    this.hideShowBasicForm = this.hideShowBasicForm.bind(this);
    this.hideShowIntermediateForm = this.hideShowIntermediateForm.bind(this);
    this.hideShowAdvancedForm = this.hideShowAdvancedForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hidden != this.state.hidden && this.state.hidden === false) {
      if (this.contentRef.current) {
        this.contentRef.current.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
      }
    }
  }

  hideShowBasicForm() {
    this.setState({
      basicForm: !this.state.basicForm,
      intermediateForm: false,
      advancedForm: false,
    });
  }

  hideShowIntermediateForm() {
    this.setState({
      intermediateForm: !this.state.intermediateForm,
      basicForm: false,
      advancedForm: false,
    });
  }

  hideShowAdvancedForm() {
    this.setState({
      advancedForm: !this.state.advancedForm,
      basicForm: false,
      intermediateForm: false,
    });
  }

  isContentShown() {
    return !this.state.hidden
      || this.state.firstBigCardExpanded
      || this.state.secondBigCardExpanded
      || this.state.thirdBigCardExpanded;
  }

  hideShowContent() {
    if (this.isContentShown()) {
      this.setState({
        hidden: true,
        firstBigCardExpanded: false,
        secondBigCardExpanded: false,
        thirdBigCardExpanded: false,
        basicForm: false,
        intermediateForm: false,
        advancedForm: false,
      });
    } else {
      this.setState({
        hidden: false,
      })
    }
  }

  expandFirstBigCard() {
    this.setState({
      firstBigCardExpanded: !this.state.firstBigCardExpanded,
      hidden: !this.state.hidden,
    });
  }

  expandSecondBigCard() {
    this.setState({
      secondBigCardExpanded: !this.state.secondBigCardExpanded,
      hidden: !this.state.hidden,
    });
  }

  expandThirdBigCard() {
    this.setState({
      thirdBigCardExpanded: !this.state.thirdBigCardExpanded,
      hidden: !this.state.hidden,
    });
  }

  takeMeBackToAllPackages() {
    this.setState({
      firstBigCardExpanded: false,
      secondBigCardExpanded: false,
      thirdBigCardExpanded: false,
      hidden: false,
      basicForm: false,
      intermediateForm: false,
      advancedForm: false,
    });
  }

  componentDidMount() {
    if (this.props.displayBasic) {
      this.setState({
        firstBigCardExpanded: true,
        hidden: true,
      });
    } else if (this.props.displayIntermediate) {
      this.setState({
        secondBigCardExpanded: true,
        hidden: true,
      });
    } else if (this.props.displayAdvanced) {
      this.setState({
        thirdBigCardExpanded: true,
        hidden: true,
      });
    } else if (this.props.displayNormal) {
      this.setState({
        firstBigCardExpanded: false,
        secondBigCardExpanded: false,
        thirdBigCardExpanded: false,
        hidden: true,
      });
    }
  }

  render() {
    return (
      <>
        <section className="d-flex flex-column align-content-center justify-content-center earth-eco-package py-5">
          <div className="row d-flex flex-column flex-lg-row justify-items-center">
            <div className="col-12 col-lg-6 text-center">
              <LottieAnimation animationName={'worm'} />
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column earth-text justify-content-center">
              <h1>Earth</h1>
              <h3>Eco package</h3>
              <p>
                Creating harmony between your garden, nature, and you. Our brand
                new biologically-focused gardening service allows you to
                create a place that is a heaven for both you and the wildlife
                that live there. It is more important than ever that we garden
                with nature in mind and we advise everyone to explore what we
                can do to make a difference together.
              </p>
              <Button
                onClick={this.hideShowContent}
                className={'see-more-btn align-self-center align-self-lg-end'}
              >
                {this.isContentShown() ?  'show less' : 'see more'}
              </Button>
            </div>

            <div
              ref={this.contentRef}
              className={
                this.state.hidden
                  ? "d-none"
                  : "w-100 mt-5 d-flex flex-column align-items-center flex-lg-row justify-content-lg-between cards-container"
              }
            >
              {/* first small card */}

              <div className="small-card w-100 h-100 mr-lg-5 mb-5 mb-lg-0">
                <div className="d-flex flex-column justify-content-between align-items-center h-100">
                  <div>
                    <div className="d-flex align-items-center small-card-title pl-3">
                      <h1 className="d-inline">1</h1>
                      <span>basic</span>
                    </div>
                    <div className="small-card-text px-3">
                      A service designed to suit all gardens, where bugs, birds,
                      and bigger mammals are invited in. Native plants are
                      introduced to help establish a rich eco-system.
                      This is a great beginning towards nature-based gardening.
                    </div>
                  </div>
                  <Button
                    onClick={this.expandFirstBigCard}
                    className="see-more-btn my-3 align-self-center"
                  >
                    see more
                  </Button>
                </div>
              </div>

              {/* second small card */}

              <div className="small-card w-100 h-100 mr-lg-5 mb-5 mb-lg-0">
                <div className="d-flex flex-column justify-content-between align-items-center h-100">
                  <div>
                    <div className="d-flex align-items-center small-card-title pl-3">
                      <h1 className="d-inline">2</h1>
                      <span>intermediate</span>
                    </div>
                    <div className="small-card-text px-3">
                      To take your garden to the next level, the ‘Intermediate’
                      package establishes a space that encourages more natural development.
                      Garden maintenance is carried out with the aim of
                      preserving and enriching the ecosystem. Habitat creation
                      to include water dwelling creatures further extends
                      life beyond the soil and plants. Rainwater collection eliminates
                      the need for tap water, saving the planet and your water bill.
                    </div>
                  </div>
                  <Button
                    onClick={this.expandSecondBigCard}
                    className="see-more-btn my-3 align-self-center"
                  >
                    see more
                  </Button>
                </div>
              </div>

              {/* third small card */}

              <div className="small-card w-100 h-100">
                <div className="d-flex flex-column justify-content-between align-items-center h-100">
                  <div>
                    <div className="d-flex align-items-center small-card-title pl-3">
                      <h1 className="d-inline">3</h1>
                      <span>advanced</span>
                    </div>
                    <div className="small-card-text px-3">
                    The ’Advanced’ Package is a personalised service with the
                      main objective being to include more biodiversity and restore
                      habitats within your existing garden. From the start, an ’Ecosystem Management
                      Evaluation’ is carried out by one of our knowledgeable gardeners
                      who will create a plan to maximise your garden’s potential.
                      You can share your garden with a myriad of species you never imagined,
                      as well as feel a personal connection by growing your own food.
                    </div>
                  </div>
                  <Button
                    onClick={this.expandThirdBigCard}
                    className="see-more-btn my-3 align-self-center"
                  >
                    see more
                  </Button>
                </div>
              </div>
            </div>

            {/* first big card */}

            <div
              className={
                this.state.firstBigCardExpanded
                  ? "w-100 mt-5 d-flex flex-column align-items-center cards-container"
                  : "d-none"
              }
            >
              <div className="big-card w-100">
                <div>
                  <div className="big-card-title">
                    <h1 className="d-inline">1</h1>
                    <span>basic</span>
                  </div>
                </div>

                <div className="row big-card-body d-flex flex-column flex-lg-row flex-grow-1">
                  <div className="col-12 col-lg-4">
                    <p>
                      A service designed to suit all gardens, where bugs, birds,
                      and bigger mammals are invited in. Native plants are
                      introduced to help establish a rich eco-system.
                      This is a great beginning towards nature-based gardening.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>No chemical applications</h2>
                    <p>
                      No harmful chemical applications will be used, such as weed killers,
                      fertilisers and insecticides. Weeds are removed by hand, or
                      by mulching, in appropriate areas.
                    </p>
                    <h2>Inviting wildlife</h2>
                    <p>
                      Installing bird feeders, bug hotels, log piles, and
                      hedgehog access will make your garden wildlife friendly.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>Regular mulching</h2>
                    <p>
                      Mulching borders enriches the quality of the soil,
                      locks in moisture, and is a natural barrier against weeds.
                    </p>
                    <h2>Composting</h2>
                    <p>
                      Making rich compost from the by-products of your garden
                      helps add nutrients back into the soil.
                    </p>
                    <h2>Planting native species</h2>
                    <p>
                      Adding plants that have naturally evolved to support
                      the region’s ecosystem and are already adapted to your
                      garden’s conditions.
                    </p>
                  </div>
                </div>
                <div className="big-card-buttons row d-flex flex-column justify-content-center align-items-center flex-lg-row justify-content-lg-between mb-2 mt-4">
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
                    <Button
                      onClick={this.takeMeBackToAllPackages}
                      className="all-packages-btn"
                    >
                      all packages
                    </Button>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <Button
                      // onClick={this.hideShowBasicForm}
                      onClick={this.props.goToContactSection}
                      className="book-now-btn eco-book-now"
                    >
                      book now
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* second big card */}

            <div
              className={
                this.state.secondBigCardExpanded
                  ? "w-100 mt-5 d-flex flex-column align-items-center cards-container"
                  : "d-none"
              }
            >
              <div className="big-card w-100">
                <div>
                  <div className="big-card-title">
                    <h1 className="d-inline">2</h1>
                    <span>intermediate</span>
                  </div>
                </div>

                <div className="row big-card-body d-flex flex-column flex-lg-row flex-grow-1">
                  <div className="col-12 col-lg-4">
                    <p>
                      To take your garden to the next level, the ‘Intermediate’
                      package establishes a space that encourages more natural development.
                      Garden maintenance is carried out with the aim of
                      preserving and enriching the ecosystem. Habitat creation
                      to include water dwelling creatures further extends
                      life beyond the soil and plants. Rainwater collection eliminates
                      the need for tap water, saving the planet and your water bill.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>Using battery powered tools</h2>
                    <p>
                      Carbon emissions and noise pollution will be minimized compared
                      to when petrol powered tools are used.
                    </p>
                    <h2>Water habitat creation</h2>
                    <p>
                      Generating another form of habitat builds more
                      flora and fauna diversity. Water is a natural supply of hydration
                       for insects and wildlife as well.
                    </p>
                    <h2>No heavy cultivation</h2>
                    <p>
                      Not cultivating the soil, helps preserve the earth for those
                      that live in it. Used only when needed, little disruption is best.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>Using water butt</h2>
                    <p>
                      Installing a water butt lowers water usage in your
                      garden by storing and recycling rainwater.
                    </p>
                    <h2>No bare earth</h2>
                    <p>
                      Leaving no bare ground, every inch of growing space will be utilized.
                    </p>
                  </div>
                </div>
                <div className="big-card-buttons buttons-down row d-flex flex-column justify-content-center align-items-center flex-lg-row justify-content-lg-between mb-2 mt-4">
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
                    <Button
                      onClick={this.takeMeBackToAllPackages}
                      className="all-packages-btn"
                    >
                      all packages
                    </Button>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <Button
                      // onClick={this.hideShowIntermediateForm}
                      onClick={this.props.goToContactSection}
                      className="book-now-btn eco-book-now"
                    >
                      book now
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* third big card */}

            <div
              className={
                this.state.thirdBigCardExpanded
                  ? "w-100 mt-5 d-flex flex-column align-items-center cards-container"
                  : "d-none"
              }
            >
              <div className="big-card w-100">
                <div>
                  <div className="big-card-title">
                    <h1 className="d-inline">3</h1>
                    <span>advanced</span>
                  </div>
                </div>

                <div className="row big-card-body d-flex flex-column flex-lg-row flex-grow-1">
                  <div className="col-12 col-lg-4">
                    <p className="">
                      The ’Advanced’ Package is a personalised service with the
                      main objective being to include more biodiversity and restore
                      habitats within your existing garden. From the start, an ’Ecosystem Management
                      Evaluation’ is carried out by one of our knowledgeable gardeners
                      who will create a plan to maximise your garden’s potential.
                      You can share your garden with a myriad of species you never imagined,
                      as well as feel a personal connection by growing your own food.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>Ecosystem Management Evaluation (EMA)</h2>
                    <p>
                      An EMA assesses your garden’s potential and sets in place a
                      plan to maximise its growth.
                    </p>
                    <h2>Biodiversity surveys</h2>
                    <p>
                      Periodic surveys of the species living in your garden will
                      help indicate your garden’s progress in becoming eco-friendly.
                    </p>
                    <h2>Conversion of hedges to mixed native species</h2>
                    <p>
                      Converting to native hedgerows will require fewer fertilisers, pesticides,
                      and a lower volume of water to maintain them. Air pollution will be reduced
                      and shelter is provided for wildlife.
                    </p>
                  </div>
                  <div className="col-12 col-lg-4">
                    <h2>Wildflower meadow establishment</h2>

                    <p>
                      Establishing a wildflower meadow will provide habitat to a great number of
                      species, including birds, bees and butterflies. Pollinators and other wildlife will love it too!
                    </p>

                    <h2>Compost teas and foliar applications</h2>

                    <p>
                      Feeding the plants through both their roots and leaves,
                      will help give them an extra boost, brightening your garden even more.
                    </p>

                    <h2>Kitchen Garden</h2>

                    <p>
                      Planting and raising crops of fruits, vegetables, and herbs will supply
                      you with fresh, nutrient-dense produce. A truly local experience.
                    </p>
                  </div>
                </div>
                <div className="big-card-buttons row d-flex flex-column justify-content-center align-items-center flex-lg-row justify-content-lg-between mb-2 mt-4">
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
                    <Button
                      onClick={this.takeMeBackToAllPackages}
                      className="all-packages-btn"
                    >
                      all packages
                    </Button>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <Button
                      // onClick={this.hideShowAdvancedForm}
                      onClick={this.props.goToContactSection}
                      className="book-now-btn eco-book-now"
                    >
                      book now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div >
          <EcoBasicForm hide={!this.state.basicForm} />
        </div>
        <div>
          <EcoIntermediateForm hide={!this.state.intermediateForm} />
        </div>
        <div>
          <EcoAdvancedForm hide={!this.state.advancedForm} />
        </div>
      </>
    );
  }
}
