import React from "react";

import DefaultForm, {SERVICES, PACKAGES} from "./DefaultForm";

export default class OneOffForm extends DefaultForm {
  constructor(props)  {
    super({
      ...props,
      whichServiceForm: false,
      whichOneOffForm: true,
      whichService: SERVICES.oneOff,
      whichPackage: PACKAGES.oneOff,
    })
  }
}