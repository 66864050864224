import React from "react";

export default function OneOffHeader({isOneOffSerice, chosenPackage}) {
    return (
        <>
            <p className="p-tellUs text-center">
              You have chosen <b>{isOneOffSerice ? 'One-Off Services' : chosenPackage}</b>
            </p>
            <p className="p-tellUs text-center">
              {isOneOffSerice ? (
                <>
                Which of <b>one-off services</b> do you want to choose?
                </>
              ) : (
                <>
                Do you want to add any <b>one-off service</b>?
                </>
              )}
            </p>
        </>
    )
}