import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";

import FlowerImg from "assets/images/svg/kwiat-03.svg";
import OneOffHeader from "./parts/OneOffHeader";
import PersonalInfoForm from "./parts/PersonalInfoForm";

const API_PATH = "https://grassgo.co.uk/mail/";

export const SERVICES = {
  package: "Package",
  oneOff: "One-Off Services",
  commercial: "Commercial and public space"
}

export const PACKAGES = {
  sea: "Sea Standard Package",
  sky: "Sky Premium Package",
  earthBasic: "Earth Eco Package: BASIC",
  earthIntermediate: "Earth Eco Package: INTERMEDIATE",
  earthAdvanced: "Earth Eco Package: ADVANCED",

  commercial: SERVICES.commercial,
  oneOff: SERVICES.oneOff,
}

let formNo = 1;

function getDefaultFormState(update) {
  return {
    whichServiceForm: true,
    whichPackageForm: false,
    whichOneOffForm: false,
    personalDataForm: false,

    whichService: SERVICES.package,
    whichPackage: PACKAGES.sea,

    oneOffServices: [],

    name: "",
    email: "",
    tel: "",
    address: "",
    postcode: "",
    date: "",
    notes: "",
    other: "",
    errors: {},

    mailSent: false,
    success: false,
    error: null,
    popup: false,

    fillMe: "",
    ...update
  };

}

export default class DefaultForm extends Component {

  constructor(props)  {
    super(props);

    formNo += 1;
    this.formNo = formNo;

    this.state = getDefaultFormState(this.props);

    this.onServiceChange = this.onServiceChange.bind(this);
    this.onPackageChange = this.onPackageChange.bind(this);
    this.onOneOffServiceChange = this.onOneOffServiceChange.bind(this)

    this.goToWhichPackageForm = this.goToWhichPackageForm.bind(this);
    this.goToOneOffServicesForm = this.goToOneOffServicesForm.bind(this);

    this.goToPersonalDataForm = this.goToPersonalDataForm.bind(this);

    this.takeMeBackFromWhichPackageForm = this.takeMeBackFromWhichPackageForm.bind(
      this
    );
    this.takeMeBackFromOneOffForm = this.takeMeBackFromOneOffForm.bind(this);
    this.takeMeBackFromPersonalDataForm = this.takeMeBackFromPersonalDataForm.bind(
      this
    );

    this.oneMoreTime = this.oneMoreTime.bind(this);

    this.baseRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hide != this.props.hide && this.props.hide === false) {
      if (this.baseRef.current) {
        this.baseRef.current.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
      }
    }
  }

  oneMoreTime() {
    this.setState(getDefaultFormState(this.props));
  }

  onServiceChange(e) {
    this.setState({
      success: false,
      whichService: e.currentTarget.value,
      oneOffServices: [],
    });
  }

  goToWhichPackageForm(e) {
    e.preventDefault();

    if (this.state.whichService === SERVICES.package) {
      this.setState({
        whichServiceForm: false,
        whichPackageForm: true,
      });
    } else if (this.state.whichService === SERVICES.oneOff) {
      this.setState({
        whichServiceForm: false,
        whichOneOffForm: true,
      });
    } else if (this.state.whichService === SERVICES.commercial) {
      this.setState({
        whichServiceForm: false,
        personalDataForm: true,
      });
    }

    console.log("GOTO", this.state.whichService);
  }

  onPackageChange(e) {
    this.setState({
      success: false,
      whichPackage: e.currentTarget.value,
    });
  }

  goToOneOffServicesForm(e) {
    e.preventDefault();

    this.setState({
      whichPackageForm: false,
      whichOneOffForm: true,
    });
    console.log(this.state.whichPackage);
  }

  onOneOffServiceChange(e) {
    const val = e.target.value;
    const index = this.state.oneOffServices.indexOf(val);
    if (index != -1) {
      this.setState({
        oneOffServices:  this.state.oneOffServices.filter(item => item !== val)
      })
    } else {
      this.setState({
        oneOffServices: [...this.state.oneOffServices, val]
      })
    }
  }

  goToPersonalDataForm(e) {
    e.preventDefault();

    this.setState({
      whichOneOffForm: false,
      personalDataForm: true,
    });
    console.log(this.state.whichPackage);
  }

  takeMeBackFromWhichPackageForm() {
    this.setState({
      whichPackageForm: false,
      whichServiceForm: true,
    });
  }

  takeMeBackFromOneOffForm() {
    const newState = { whichOneOffForm: false };
    if (this.state.whichService == SERVICES.oneOff) {
      newState.whichServiceForm = true;
    } else {
      newState.whichPackageForm =true;
    }

    this.setState(newState);
  }

  takeMeBackFromPersonalDataForm() {
    const newState = {
      personalDataForm: false
    }

    switch (this.state.whichService) {
      case SERVICES.package:
      case SERVICES.oneOff:
        newState.whichOneOffForm = true;
        break;
      case SERVICES.commercial:
        newState.whichServiceForm = true;
       default:
         break;
    }

     this.setState(newState);
  }

  handleFormSubmit = (e) => {

    if (this.state.fillMe !== "") {
      alert("something went wrong :(");
    } else {
      console.log(this.state);

      this.setState({
        popup: true,
        personalDataForm: false,
      });

      fetch(API_PATH, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state) // body data type must match "Content-Type" header
      }).then((result) => {
        console.log(result)
        this.setState({
          mailSent: result.sent,
          success: true,
          popup: false,
        });
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });

    }
  };

  render() {
    return (
      <>
        <div ref={this.baseRef} className={`
            form-section package-story-form py-5
            ${this.props.hide ? 'd-none' : ''}
          `}>
          {/* always on top content start */}
          <div className="row text-center mb-5">
            <div className="col-12">
              <h1 className="form__title mb-3">Book your visit</h1>
              <input
                type="text"
                className="fill-me"
                value=""
                onChange={(e) => this.setState({ fillMe: e.target.value })}
              ></input>
            </div>
            <div className="col-12 col-lg-6">
              <a className="form__phone-number" href="tel:01414040434">
                0141 404 0434
              </a>
            </div>
            <div className="col-12 col-lg-6">
              <a className="form__mail" href="mailto: info@grassgo.co.uk">
                info@grassgo.co.uk
              </a>
            </div>
          </div>
          {/* always on top content end */}

          {/* which service form start */}
          <form
            className={
              this.state.whichServiceForm
                ? "row form d-flex flex-column align-items-center"
                : "d-none"
            }

          >
            <p className="p-tellUs text-center">
              Let us know which service would you like to book
            </p>
            <div className="service-wrapper mb-2">
              <div className="">
                <input
                  type="radio"
                  id={"package" + this.formNo}
                  name="whichServiceName"
                  value={SERVICES.package}
                  checked={this.state.whichService === SERVICES.package}
                  onChange={this.onServiceChange}
                />
                <label className="ml-3 form-label" htmlFor={"package" + this.formNo}>
                  package
                </label>
              </div>
              <div className="">
                <input
                  type="radio"
                  id={"oneoff" + this.formNo}
                  name="whichServiceName"
                  value={SERVICES.oneOff}
                  checked={this.state.whichService === SERVICES.oneOff}
                  onChange={this.onServiceChange}
                />
                <label className="ml-3 form-label" htmlFor={"oneoff" + this.formNo}>
                  one-off
                </label>
              </div>
              <div className="">
                <input
                  type="radio"
                  id={"commercialpublic" + this.formNo}
                  name="whichServiceName"
                  value={SERVICES.commercial}
                  checked={this.state.whichService === SERVICES.commercial}
                  onChange={this.onServiceChange}
                />
                <label className="ml-3 form-label" htmlFor={"commercialpublic" + this.formNo}>
                  commercial and public space
                </label>
              </div>
            </div>

            <div className="col-12 text-center text-lg-right ml-lg-4">
              <button
                className="next-btn ml-lg-auto mr-lg-5"
                onClick={this.goToWhichPackageForm}
              >
                next
              </button>
            </div>
          </form>
          {/* which service form finish */}

          {/* which package form start */}
          <form
            className={
              this.state.whichService === SERVICES.package &&
              this.state.whichPackageForm &&
              !this.state.whichServiceForm
                ? "col-12 form d-flex flex-column align-items-center"
                : "d-none"
            }
          >
            <p className="p-tellUs text-center">Which package do you prefer?</p>
            <div className="col-10 col-md-5 col-lg-4">
              <input
                onChange={this.onPackageChange}
                checked={this.state.whichPackage === PACKAGES.sea}
                type="radio"
                id={"standardpackage" + this.formNo}
                name="whichPackageName"
                value={PACKAGES.sea}
              />
              <label className="ml-3 form-label" htmlFor={"standardpackage" + this.formNo}>
                {PACKAGES.sea}
              </label>
            </div>
            <div className="col-10 col-md-5 col-lg-4">
              <input
                onChange={this.onPackageChange}
                checked={this.state.whichPackage === PACKAGES.sky}
                type="radio"
                id={"premiumpackage" + this.formNo}
                name="whichPackageName"
                value={PACKAGES.sky}
              />
              <label className="ml-3 form-label" htmlFor={"premiumpackage" + this.formNo}>
                {PACKAGES.sky}
              </label>
            </div>
            <div className="col-10 col-md-5 col-lg-4 ml-4">
              <label className="form-label ml-3" htmlFor={"ecopackage" + this.formNo}>
                Earth Eco Package
              </label>
              <br />
              <input
                className="ml-4"
                onChange={this.onPackageChange}
                checked={this.state.whichPackage === PACKAGES.earthBasic}
                type="radio"
                id={"ecopackageBasic" + this.formNo}
                name="whichPackageName"
                value={PACKAGES.earthBasic}
              />
              <label className="ml-3 form-label" htmlFor={"ecopackageBasic" + this.formNo}>
                Basic
              </label>

              <br />
              <input
                className="ml-4"
                onChange={this.onPackageChange}
                checked={this.state.whichPackage === PACKAGES.earthIntermediate}
                type="radio"
                id={"ecopackageIntermediate" + this.formNo}
                name="whichPackageName"
                value={PACKAGES.earthIntermediate}
              />
              <label
                className="ml-3 form-label"
                htmlFor={"ecopackageIntermediate" + this.formNo}
              >
                Intermediate
              </label>

              <br />
              <input
                className="ml-4"
                onChange={this.onPackageChange}
                checked={this.state.whichPackage === PACKAGES.earthAdvanced}
                type="radio"
                id={"ecopackageAdvanced" + this.formNo}
                name="whichPackageName"
                value={PACKAGES.earthAdvanced}
              />
              <label className="ml-3 form-label" htmlFor={"ecopackageAdvanced" + this.formNo}>
                Advanced
              </label>
            </div>
            <div className="row mt-3 text-center w-100 d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-md-center">
              <div className="col-lg-3 col-12 text-center">
                <span
                  className="back-btn"
                  onClick={this.takeMeBackFromWhichPackageForm}
                >
                  <u>back</u>
                </span>
              </div>
              <div className="col-12 col-lg-6 text-center text-lg-right">
                <button
                  className="next-btn"
                  onClick={this.goToOneOffServicesForm}
                >
                  next
                </button>
              </div>
            </div>
          </form>
          {/* which package form finish */}

          {/* one off services form start */}
          <form
            className={
              this.state.whichOneOffForm
                ? "col-12 form d-flex flex-column align-items-center"
                : "d-none"
            }
          >
            <OneOffHeader
              isOneOffSerice={this.state.whichService === SERVICES.oneOff}
              chosenPackage={this.state.whichPackage}
            />
            <div className="col-12 col-md-5 col-lg-4">
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("CleanUp")}
                  type="checkbox"
                  id={"cleanup" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="cleanup"
                  value="CleanUp"
                />
                <label className="ml-3 form-label" htmlFor={"cleanup" + this.formNo}>
                  clean-up {this.state.oneOffServices.includes("CleanUp")}
                </label>
              </div>
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Hedge Trim")}
                  type="checkbox"
                  id={"hedgeTrim" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="hedgeTrim"
                  value="Hedge Trim"
                />
                <label className="ml-3 form-label" htmlFor={"hedgeTrim" + this.formNo}>
                  hedge trim
                </label>
              </div>
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Tree Surgery")}
                  type="checkbox"
                  id={"treeSurgery" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="treeSurgery"
                  value="Tree Surgery"
                />
                <label className="ml-3 form-label" htmlFor={"treeSurgery" + this.formNo}>
                  tree surgery
                </label>
              </div>
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Lawn care")}
                  type="checkbox"
                  id={"lawnCare" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="lawnCare"
                  value="Lawn care"
                />
                <label className="ml-3 form-label" htmlFor={"lawnCare" + this.formNo}>
                  lawn care
                </label>
              </div>
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Planting")}
                  type="checkbox"
                  id={"planting" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="planting"
                  value="Planting"
                />
                <label className="ml-3 form-label" htmlFor={"planting" + this.formNo}>
                  planting
                </label>
              </div>
              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Pruning")}
                  type="checkbox"
                  id={"pruning" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="pruning"
                  value="Pruning"
                />
                <label className="ml-3 form-label" htmlFor={"pruning" + this.formNo}>
                  pruning
                </label>
              </div>

              <div>
                <input
                  onChange={this.onOneOffServiceChange}
                  checked={this.state.oneOffServices.includes("Japanese Knotweed removal")}
                  type="checkbox"
                  id={"knotweed" + this.formNo}
                  className="my-checkbox styled-checkbox"
                  name="knotweed"
                  value="Japanese Knotweed removal"
                />
                <label
                  className="ml-3 form-label japanese w-100"
                  htmlFor={"knotweed" + this.formNo}
                >
                  japanese knotweed removal
                </label>
              </div>
              <div className="d-flex flex-column">
                <label className="mx-3 form-label" htmlFor={"other" + this.formNo}>
                  other:
                </label>
                <input
                  onChange={(e) => this.setState({ other: e.target.value })}
                  type="text"
                  id={"other" + this.formNo}
                  className="ml-3 w-100"
                  name="other input-other"
                  value={this.state.other}
                />
              </div>
            </div>

            <div className="row mt-3 text-center w-100 d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-md-center">
              <div className="col-lg-3 col-12 text-center">
                <span
                  className="back-btn"
                  onClick={this.takeMeBackFromOneOffForm}
                >
                  <u>back</u>
                </span>
              </div>
              <div className="col-12 col-lg-6 text-center text-lg-right">
                <button
                  className="next-btn"
                  onClick={this.goToPersonalDataForm}
                >
                  next
                </button>
              </div>
            </div>
          </form>
          {/* one off services form finish */}

          {/* personal data form start*/}
            <PersonalInfoForm
              state={this.state} // this is some serious hack :(
              updateState={(updateContent) => {
                this.setState(updateContent);
              }}
              formNo={this.formNo}
              onGoBack={this.takeMeBackFromPersonalDataForm}
              onFormSubmit={this.handleFormSubmit}
            />
          {/* personal data form finish*/}

          <div
            className={
              this.state.success === true
                ? "col-12 form d-flex flex-column align-items-center"
                : "d-none"
            }
          >
            <p className="p-success">Thank you!</p>
            <p>Your form was sent and we will contact you soon!</p>
            <div>
              <Button className="next-btn" onClick={this.oneMoreTime}>
                book another visit
              </Button>
            </div>
          </div>

          {/* popup sending data */}
          <div className={this.state.popup ? "form-popup__bg" : "d-none"}>
            <div className={this.state.popup ? "form-popup" : "d-none"}>
              <div className="col-12 text-center d-flex flex-column justify-content-center align-items-center h-100">
                <img
                  src={FlowerImg}
                  alt="flower"
                  className="flower-img rotate-center"
                />
                <p>your message is being processed</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
