import React, { Component } from "react";
import SkyImage from "assets/images/svg/exported/bird-image.svg";
import { GreenButton, OrangeButton } from "../Buttons";
import PremiumPackageForm from "../Form/PremiumPackageForm";
import LottieAnimation from "components/Animations/animations";

export default class SkyPremiumPackage extends Component {
  constructor() {
    super();

    this.state = {
      hidden: true,
      form: false,
    };

    this.contentRef = React.createRef();

    this.hideShowContent = this.hideShowContent.bind(this);
    this.hideShowForm = this.hideShowForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hidden != this.state.hidden && this.state.hidden === false) {
      if (this.contentRef.current) {
        this.contentRef.current.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
        this.setState({
          form: false,
        })
      }
    }
  }

  hideShowContent() {
    this.setState({
      hidden: !this.state.hidden,
    });
  }

  hideShowForm() {
    this.setState({
      form: !this.state.form,
    });
  }

  render() {
    return (
      <>
        <section className="sky-premium-package d-flex flex-column align-content-center justify-content-center py-5">
          <div className="row d-flex flex-column flex-lg-row justify-items-center">
            <div className="col-12 col-lg-6 text-center">
              <LottieAnimation animationName={"birds"} />
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center sky-text">
              <h1>Sky</h1>
              <h3>Premium package</h3>
              <p>
                Gardening service, with all the trimmings. In addition to
                all the maintenance from the standard ‘Sea’ package, your garden will
                further benefit from our extra expert support, plus <b>free </b>
                bedding plants and rock salt. We would highly recommend this
                service to any client with a large garden, or who wants their
                garden to be regularly maintained to a very high standard.
              </p>
              <GreenButton
                title={this.state.hidden?  'see more' : 'show less'}
                onClick={this.hideShowContent}
                className={`see-more-btn align-self-center align-self-lg-end`}
              >
                see more
              </GreenButton>
            </div>

            <div
              ref={this.contentRef}
              className={
                this.state.hidden ? "d-none" : "col-12 d-flex flex-column"
              }
            >
              <div className="row sky-list">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 d-md-flex pl-2">
                  <table>
                    <tbody>
                      <tr>
                        <td>monitoring gardens</td>
                        <td>pruning</td>
                      </tr>
                      <tr>
                        <td>grass cutting</td>
                        <td>hedge and shrub trimming</td>
                      </tr>
                      <tr>
                        <td>edging of lawns</td>
                        <td>leaf removal</td>
                      </tr>
                      <tr>
                        <td>weeding / hoeing</td>
                        <td>deadheading</td>
                      </tr>
                      <tr>
                        <td>general clearance of beds</td>
                        <td>snow removal and spreading salt</td>
                      </tr>
                      <tr>
                        <td>fertilising plants</td>
                        <td>spraying of weeds</td>
                      </tr>
                      <tr>
                        <td>supply seasonal bedding plants</td>
                        <td>drain cover cleaning</td>
                      </tr>
                      <tr>
                        <td>aeration, scarification and sanding of lawns</td>
                        <td>winter plant protection</td>
                      </tr>
                      <tr>
                        <td>planting new plants</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-12 col-lg-6 d-flex flex-column flex-lg-row">
                  <p className="col-12 col-lg-6 date-p small-text pl-0">
                    1 visit, per fortnight during the vegetation season (March –
                    November)<br></br>1 visit per 4 weeks in winter season
                    (December – February)
                  </p>
                  <div className="col-12 col-lg-6 text-center">
                    <OrangeButton
                      title="book now"
                      onClick={this.props.goToContactSection}
                      // onClick={this.hideShowForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <PremiumPackageForm hide={!this.state.form}/>
        </div>
      </>
    );
  }
}
