import React from 'react';
import { Button } from "react-bootstrap";

import ContactFormHeader from './ContactFormHeader'

const REQUIRED_TEXT = 'This field is obligatory';
const INVALID_FORMAT_TEXT = 'Wrong format';
const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validateName(name) {
  if (!name || name.length == 0) {
    return REQUIRED_TEXT
  }
  return false;
}
function validateEmail(email) {
  if (!email || email.length == 0) {
    return REQUIRED_TEXT
  }

  if (!EMAIL_RE.test(email.toLowerCase())) {
    return INVALID_FORMAT_TEXT;
  }

  return false;
}

function validateTel(tel) {
  if (!tel || tel.length == 0) {
    return REQUIRED_TEXT
  }
}
function validateAddress(address) {
  if (!address || address.length == 0) {
    return REQUIRED_TEXT
  }
}
function validatePostcode(postcode) {
  if (!postcode || postcode.length == 0) {
    return REQUIRED_TEXT
  }
}
function validateDate(date) {
  if (!date || date.length == 0) {
    return REQUIRED_TEXT
  }
}

function validate(state) {
  const errors = {
    name: validateName(state.name),
    email: validateEmail(state.email),
    tel: validateTel(state.tel),
    address: validateAddress(state.address),
    postcode: validatePostcode(state.postcode),
    date: validateDate(state.date),
  }

  let hasErrors = false;
  for (let key in errors) {
    if (errors[key]){
      hasErrors = true;
      break;
    }
  }

  if (hasErrors) {
    return errors
  } else {
    return null;
  }
}

function ErrorDiv({error}) {
  return (<>
    {error && (<div className="error">{error}</div>)}
  </>)
}

export default function PersonalInfoForm({state, updateState, formNo, onGoBack, onFormSubmit}) {

    const _onFormSubmit = (e) => {
      e.preventDefault()

      const errors = validate(state);
      if (errors === null) {
        onFormSubmit(e)
      } else {
        updateState({
          errors: errors
        })
      }
    }

    return (
        <form
            className={
              state.personalDataForm
                ? "col-12 form d-flex flex-column align-items-center personal-data-form"
                : "d-none"
            }
          >
            <ContactFormHeader
              service={state.whichService}
              packageName={state.whichPackage}
              oneOffs={state.oneOffServices}
            />
            <p className="p-tellUs text-center">
              Please give us some details, so we can plan the visit
            </p>
            <div className="personal-data-input">
              <input
                required
                onChange={(e) => updateState({ name: e.target.value })}
                value={state.name}
                type="text"
                id={"name" + formNo}
                name="name"
                placeholder="Name"
                className={`
                input-name
                ${state.errors.name && 'error'}
                `}
              />
              <ErrorDiv error={state.errors.name} />
            </div>
            <div className="personal-data-input">
              <input
                required
                onChange={(e) => updateState({ email: e.target.value })}
                value={state.email}
                type="email"
                id={"email" + formNo}
                name="email"
                placeholder="e-mail"
                className={`
                  input-email
                  ${state.errors.email && 'error'}
                `}
              />
              <ErrorDiv error={state.errors.email} />
            </div>
            <div className="personal-data-input">
              <input
                required
                value={state.tel}
                onChange={(e) => updateState({ tel: e.target.value })}
                type="tel"
                id={"tel" + formNo}
                name="tel"
                placeholder="Phone no"
                className={`
                  input-tel
                  ${state.errors.tel && 'error'}
                `}
              />
              <ErrorDiv error={state.errors.tel} />
            </div>
            <div className="mb-lg-2 input-group personal-data-input">
              <div className="w-50">
                <input
                  required
                  value={state.address}
                  onChange={(e) => updateState({ address: e.target.value })}
                  type="text"
                  id={"address" + formNo}
                  name="address"
                  placeholder="Street"
                  className={`
                    input-address
                    ${state.errors.address && 'error'}
                  `}
                />
                <ErrorDiv error={state.errors.address} />
              </div>
              <div className="w-50">
                <input
                  required
                  onChange={(e) => updateState({ postcode: e.target.value })}
                  type="text"
                  value={state.postcode}
                  id={"postcode" + formNo}
                  name="postcode"
                  placeholder="Postcode"
                  className={`
                    input-postcode
                    ${state.errors.postcode && 'error'}
                  `}
                />
                <ErrorDiv error={state.errors.postcode} />
              </div>
            </div>
            <div className="personal-data-input">
              <input
                required
                onChange={(e) => updateState({ date: e.target.value })}
                value={state.date}
                type="date"
                id={"date" + formNo}
                name="date"
                placeholder="Prefferred date of service"
                className={`
                  input-date
                  ${state.errors.date && 'error'}
                `}
              />
              <ErrorDiv error={state.errors.date} />
            </div>
            <textarea
              onChange={(e) => updateState({ notes: e.target.value })}
              rows="5"
              cols="23"
              value={state.notes}
              id={"notes" + formNo}
              name="notes"
              placeholder="Additional remarks"
              className={`
                personal-data-input input-notes
                ${state.errors.notes && 'error'}
              `}
            ></textarea>
            <div className="row mt-3 text-center w-100 d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-md-center">
              <div className="col-lg-3 col-12 text-center">
                <span
                  className="back-btn"
                  onClick={onGoBack}
                >
                  <u>back</u>
                </span>
              </div>
              <div className="col-12 col-lg-6 text-center text-lg-right">
                <Button className="next-btn" onClick={_onFormSubmit}>
                  send
                </Button>
              </div>
            </div>
          </form>
    )
}