import React, { useState, useEffect } from 'react';
import LottieAnimation from './animations';

import useWindowDimensions from 'components/hooks';

import CLayerSvg from 'assets/animations/main/C.svg';

import LeftMargin from 'assets/images/left-margin.svg'
import RightMargin from 'assets/images/right-margin.svg'

const CONFIGS = {
  desktop: {
    bOffset: 73,
    cOffset: 2,
    cMaxScroll: 45,
    paralaxCut: 800,
    width: '1920px',
    cLayer: CLayerSvg,
    anims: {
      a: 'layerA',
      b: 'layerB',
      d: 'layerD',
    },
    height: {
      a: '1110px',
      b: '938px',
      c: '938px',
      d: '938px',
    }
  }
}

const C_MIN_HEIGHT_THRESHOLD = 981;
const C_STAGE_2_THRESHOLD = 1080;
const STREATCH_THRESHOLD = 1110;

export default function Splash () {
  const [frac, setFrac] = useState(0)

  const [config, setConfig] = useState(CONFIGS.desktop)

  const { height, width } = useWindowDimensions();

  const smallSreenComp = height < 851 ? 150 : 0;

  useEffect(() => {
    let lastKnownScrollPosition = 0
    let ticking = false

    function doSomething (scrollPos) {
      const frac = Math.min((scrollPos) / config.paralaxCut, 1)
      setFrac(frac);
    }

    document.addEventListener('scroll', function (e) {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          doSomething(lastKnownScrollPosition);
          ticking = false;
        })

        ticking = true;
      }
    });
  }, []);

  let cComp = 0
  if (height > 915) {
    cComp = (-height + 915) * 0.145
  }

  return (
    <div
      className={`splash-container img-fluid`}
      style={{
        'marginBottom': `${config.paralaxCut * 1.25}px`,
      }}
    >
      <div
        className='inner-wrapper'
        // style={{
        //   'position': scrollDone ? 'absolute' : 'fixed',
        //   'top': `${scrollDone ? config.paralaxCut : 0}px`,
        // }}>
        style={{
          'position': 'fixed',
          'top': height > 850 ? `0px` : `-150px`,
        }}>
        <div
          className='layer-wrapper layer-a'
          style={{ transform: `translateY(calc(${frac} * (-100% + 100vh + ${smallSreenComp}px)))` }}
        >
          <img src={LeftMargin} className="overflow-margin left" />
          <LottieAnimation
            height={'auto'}
            animationName={config.anims.a}
            aspectRatio={'xMidYMin slice'}
            height={config.height.a}
            width={config.width}
          />
          <img src={RightMargin} className="overflow-margin right" />
        </div>
        <div
          className='layer-wrapper layer-b'
          style={{ transform: `translateY(calc(${frac} * (-100% + 100vh - ${config.bOffset}px + ${smallSreenComp}px) + ${config.bOffset}px))` }}
        >
          <LottieAnimation
            height={'auto'}
            animationName={config.anims.b}
            aspectRatio={'xMidYMid slice'}
            height={config.height.b}
            width={config.width}
          />
          <img
            className="image-c"
            src={config.cLayer}
            style={{
              height: 'calc(max(100%, 938px))',
              transform: `translateY(calc(-90px + ${cComp}px + (30px * ${frac}))) translateX(-50%)`
            }}
          />
        </div>
        <div className='layer-wrapper layer-d'>
          <LottieAnimation
            height={'auto'}
            animationName={config.anims.d}
            aspectRatio={'xMidYMid slice'}
            height={config.height.d}
            width={config.width}
          />
        </div>
      </div>
    </div>
  )
}
