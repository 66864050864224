import React, { useEffect, useState, useRef, Component } from 'react'
import { useWindowScroll } from 'react-use'

import AboutUs from './components/AboutUs/AboutUs'
import PackagesBar from './components/Packages/PackagesBar'
import SeaStandardPackage from './components/Packages/SeaStandardPackage'
import SkyPremiumPackage from './components/Packages/SkyPremiumPackage'
import EarthEcoPackage from './components/Packages/EarthEcoPackage'
import OneOffServicesPackage from './components/Packages/OneOffServicesPackage'
import CommercialAndPublic from './components/Packages/CommercialAndPublic'
import DefaultForm from './components/Form/DefaultForm'
import Footer from './components/Footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faSortDown,
  faSortUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest
} from '@fortawesome/free-brands-svg-icons'

import MenuHamburgerIcon from './assets/images/menun.svg'
import closeIcon from './assets/images/close.svg'
import logo from './assets/images/logo.svg'
import LottieAnimation from 'components/Animations/animations'
import Splash from 'components/Animations/splash'
import ToggleArrow from 'components/Utils/ToggleArrow'

const PARALAX_LOGO_CUTOFF = 0.8
const NORMAL_LOGO_CUTOFF = 0.2

export default function App () {
  const [showNav, setShowNav] = useState(false)
  const [showPackagesNav, setShowPackagesNav] = useState(false)
  const [showEcoPackagesNav, setShowEcoPackagesNav] = useState(false)

  const aboutUsSection = useRef(null)
  const standardPackageSection = useRef(null)
  const premiumPackageSection = useRef(null)
  const ecoPackageSection = useRef(null)
  const oneOffSection = useRef(null)
  const commercialSection = useRef(null)
  const contactSection = useRef(null)
  const ecoPackageSpecificSection = useRef(null)

  const [ecoBasic, showEcoBasic] = useState(false)
  const [ecoIntermediate, showEcoIntermediate] = useState(false)
  const [ecoAdvanced, showEcoAdvanced] = useState(false)

  const { y } = useWindowScroll()
  const vieportFrac = y / window.innerHeight;

  useEffect(() => {
    document.querySelector('body').classList.toggle('no-scroll', showNav);
  }, [showNav]);

  let logoCutOff = false
  if (window.innerWidth > 1250) {
    logoCutOff = vieportFrac > PARALAX_LOGO_CUTOFF
  } else {
    logoCutOff = vieportFrac > NORMAL_LOGO_CUTOFF
  }

  const scrollWindowTo = (topOffset) => {
    window.scrollTo({
      top: topOffset,
      behavior: 'smooth'
    });

    setShowNav(false);
  }

  const goToAboutUsSection = () => {
    scrollWindowTo(aboutUsSection.current.offsetTop);
  }

  const goToStandardPackageSection = () => {
    scrollWindowTo(standardPackageSection.current.offsetTop);
  }

  const goToPremiumPackageSection = () => {
    scrollWindowTo(premiumPackageSection.current.offsetTop);
  }

  const goToEcoPackageSection = function () {
    showEcoBasic(false)
    showEcoIntermediate(false)
    showEcoAdvanced(false)
    scrollWindowTo(ecoPackageSection.current.offsetTop);
  }

  const goToEcoBasicPackageSection = function () {
    showEcoBasic(!ecoBasic)
    showEcoIntermediate(false)
    showEcoAdvanced(false)

    scrollWindowTo(ecoPackageSpecificSection.current.offsetTop);
  }

  const goToEcoIntermediatePackageSection = function () {
    showEcoIntermediate(!ecoIntermediate)
    showEcoBasic(false)
    showEcoAdvanced(false)

    scrollWindowTo(ecoPackageSpecificSection.current.offsetTop);
  }

  const goToEcoAdvancedPackageSection = function () {
    showEcoAdvanced(!ecoAdvanced)
    showEcoIntermediate(false)
    showEcoBasic(false)

    scrollWindowTo(ecoPackageSpecificSection.current.offsetTop);
  }

  const goToOneOffSection = () => {
    scrollWindowTo(oneOffSection.current.offsetTop);
  }

  const goToCommercialSection = () => {
    scrollWindowTo(commercialSection.current.offsetTop);
  }

  const goToContactSection = () => {
    scrollWindowTo(contactSection.current.offsetTop);
  }

  return (
    <div className='App'>
      <div
        className={`backdrop ${(showNav && 'active') || ''}`}
        onClick={() => setShowNav(!showNav)}
        onMouseDown={() => setShowNav(false)}
        onTouchStart={() => setShowNav(false)}
      ></div>

      <div className={`menu`}>
        <div className='menu-wrapper'>
          <div className='open-nav' onClick={() => setShowNav(!showNav)}>
            <img src={MenuHamburgerIcon} alt='hamburger' />
          </div>
          <img
            alt=''
            src={logo}
            className={`
              grassgo-logo
              ${logoCutOff ? 'past' : ''}
            `}
          />
        </div>
        <nav className={`d-flex flex-column justify-content-between menu-bar ${showNav && 'open'}`}>
          <div className='container w-75 d-flex flex-column align-items-start mt-4'>
            <div
              className='close-nav mb-3'
              onClick={() => setShowNav(!showNav)}
            >
              <img
                src={closeIcon}
                style={{ width: '30px', height: '30px' }}
                alt='close'
              />
            </div>
            <div className='mt-5 mb-2' onClick={goToAboutUsSection}>
              about us
            </div>
            <div className='my-2'>
              <span onClick={() => setShowPackagesNav(!showPackagesNav)}>
                packages{' '}
                <ToggleArrow open={showPackagesNav} />
              </span>
              {showPackagesNav && (
                <ul className='container mb-0 font-weight-normal'>
                  <li className='my-2' onClick={goToStandardPackageSection}>
                    standard
                  </li>
                  <li className='my-2' onClick={goToPremiumPackageSection}>
                    premium
                  </li>
                  <li className='mt-2'>
                    <span onClick={goToEcoPackageSection}>eco{' '}</span>
                    <ToggleArrow open={showEcoPackagesNav} onClick={() => setShowEcoPackagesNav(!showEcoPackagesNav)} />
                  </li>
                  {showEcoPackagesNav && (
                    <ul className='container'>
                      <li className='my-2' onClick={goToEcoBasicPackageSection}>
                        basic
                      </li>
                      <li
                        className='my-2'
                        onClick={goToEcoIntermediatePackageSection}
                      >
                        intermediate
                      </li>
                      <li
                        className='mt-2'
                        onClick={goToEcoAdvancedPackageSection}
                      >
                        advanced
                      </li>
                    </ul>
                  )}
                </ul>
              )}
            </div>
            <div className='my-2' onClick={goToOneOffSection}>
              one-off
            </div>
            <div className='my-2' onClick={goToCommercialSection}>
              commercial
            </div>
            <div className='my-2' onClick={goToContactSection}>
              contact
            </div>
            <div className='my-2'>
              <a className='blog-link' href='/blog/'>blog</a>
            </div>
          </div>
          <div className='container w-75 mb-5 nav__footer'>
            <a
              className='nav-footer-branding mb-3'
              href='http://northhillgardens.co.uk'
              target='_blank'
              rel='noopener noreferrer'
            ></a>
            <div className='nav__footer--social'>
              <a
                href='https://www.instagram.com/grassgo_gardeners/'
                target='_blank'
                rel='noopener noreferrer'
                className='text-white mr-2'
              >
                <FontAwesomeIcon icon={faInstagram} className='fa mx-1' />
              </a>
              <a
                href='https://twitter.com/GrassGo_Grdnrs'
                target='_blank'
                rel='noopener noreferrer'
                className='text-white mr-2'
              >
                <FontAwesomeIcon icon={faTwitter} className='fa mx-1' />
              </a>
              <a
                href='https://www.facebook.com/grassgoltd'
                target='_blank'
                rel='noopener noreferrer'
                className='text-white'
              >
                <FontAwesomeIcon icon={faFacebook} className='fa mx-1' />
              </a>
              <a
                href='https://www.pinterest.co.uk/grassgo_gardeners'
                target='_blank'
                rel='noopener noreferrer'
                className='text-white'
              >
                <FontAwesomeIcon icon={faPinterest} className='fa ml-3' />
              </a>
            </div>
          </div>
        </nav>
      </div>
      <header>
        <div className={'paralax-animation'}>
          <Splash />
        </div>
        <div className={'tablet-animation'}>
          <LottieAnimation
            animationName='tablet'
            aspectRatio={'xMidYMid slice'}
            height={'100vh'}
          />
        </div>
        <div className={'mobile-animation'}>
          <LottieAnimation
            animationName='mobile'
            aspectRatio={'xMidYMid slice'}
            height={'100vh'}
          />
        </div>
      </header>
      <span ref={aboutUsSection}></span>
      <AboutUs />
      <PackagesBar />
      <span ref={standardPackageSection}></span>
      <SeaStandardPackage goToContactSection={goToContactSection} />
      <span ref={premiumPackageSection}></span>
      <SkyPremiumPackage goToContactSection={goToContactSection} />
      <span ref={ecoPackageSection}></span>
      <span ref={ecoPackageSpecificSection} className='eco-exact-span'></span>
      {ecoBasic && (!ecoIntermediate || !ecoAdvanced) && (
        <EarthEcoPackage displayBasic={true} goToContactSection={goToContactSection} />
      )}
      {ecoIntermediate && (!ecoBasic || !ecoAdvanced) && (
        <EarthEcoPackage displayIntermediate={true} goToContactSection={goToContactSection} />
      )}
      {ecoAdvanced && (!ecoBasic || !ecoIntermediate) && (
        <EarthEcoPackage displayAdvanced={true} goToContactSection={goToContactSection} />
      )}

      {!ecoBasic && !ecoIntermediate && !ecoAdvanced && (
        <EarthEcoPackage displayNormal={true} goToContactSection={goToContactSection} />
      )}
      <span ref={oneOffSection}></span>
      <OneOffServicesPackage goToContactSection={goToContactSection} />
      <span ref={commercialSection}></span>
      <CommercialAndPublic goToContactSection={goToContactSection} />
      <DefaultForm />
      <section className='back-to-top-wrapper'>
        <div className='row'>
          <div className='col-12 text-center text-lg-right ml-lg-4'>
            <a href="#">
              <button className="align-self-center mr-lg-5 align-self-lg-end green-button">
                back to top
              </button>
            </a>
          </div>
        </div>
      </section>
      <span ref={contactSection}></span>
      <Footer goToTop={goToAboutUsSection} />
    </div>
  )
}
