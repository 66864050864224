import React, { Component } from "react";
import OneOffCarousel from "./OneOffCarousel";
import OneOffImage from "assets/images/svg/Ilustracje_nowe_7.svg";
import MoreDots from "assets/images/more-dots.png";
import { Button } from "react-bootstrap";
import OneOffForm from "../Form/OneOffForm";
import SkyImage from "../../assets/images/svg/latest/Ilustracje_4_Sky_grouped.svg";
import LottieAnimation from "components/Animations/animations";

export default class OneOffServicesPackage extends Component {
  constructor() {
    super();

    this.state = {
      form: false,
    };

    // Turned off because it confuses the logic
    // this.hideShowForm = this.hideShowForm.bind(this);
  }
  
  // Turned off because it confuses the logic
  // before changes this was used in "book now" btn
  // hideShowForm() {
  //   this.setState({
  //     form: !this.state.form,
  //   });
  // }

  render() {
    return (
      <>
        <section className="d-flex flex-column align-content-center  justify-content-center one-off-package py-5">
          <div className="row d-flex flex-column flex-lg-row-reverse justify-items-center">
            <div className="col-12 col-lg-6 text-center">
              <div className="barrow-animation-wrapper">
                <LottieAnimation width={'90%'}  animationName={'barrow'} />
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column sky-text justify-content-center">
              <h1>One-off services</h1>
              <p>
                If regular garden maintenance is not what you’re looking for,
                we offer a one-off service suited to your whatever you require.
                This could be anything from hedge trimming,
                to treatment of Japanese knotweed, or a large-scale clear-out.
                Fact: these clear-outs can help get your wild garden
                into a manageable state and make a regular maintenance program more accessible.
              </p>
            </div>

            <div className="col-12 order-4">
              <OneOffCarousel />
            </div>

            <div className="col-12 order-5 my-5 d-flex justify-content-center justify-content-lg-end">
              <Button
                onClick={this.props.goToContactSection}
                className="book-now-btn mt-3 mt-lg-0"
              >
                book now
              </Button>
            </div>
          </div>
        </section>
        <div>
          <OneOffForm hide={!this.state.form}/>
        </div>
      </>
    );
  }
}
