import React from 'react'
import { SERVICES, PACKAGES } from '../DefaultForm'

function OneOffHeader ({oneOffs}) {
  return (
    <p className='p-tellUs text-center'>
      You have chosen <b>{SERVICES.oneOff}</b>: <b>{oneOffs.join(', ')}</b>
    </p>
  )
}

function PackageHeader ({packageName, oneOffs}) {
  return (
    <p className='p-tellUs text-center'>
      You have chosen <b>{packageName} </b>
      <span className={oneOffs.length === 0 ? 'd-none' : 'd-inline'}>
        and <b>{oneOffs.join(', ')}</b>
      </span>
    </p>
  )
}

function CommercialHeader () {
  return (
    <p className='p-tellUs text-center'>
      You have chosen <b>{SERVICES.commercial} care</b>.
    </p>
  )
}

export default function ContactFormHeader ({ service, packageName, oneOffs }) {
  if (service == SERVICES.commercial) {
    return <CommercialHeader />
  } else if (service == SERVICES.oneOff) {
    return <OneOffHeader oneOffs={oneOffs} />
  } else {
    return <PackageHeader packageName={packageName} oneOffs={oneOffs} />
  }
}
