import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lottie from 'react-lottie';

import WormAnimation from 'assets/animations/robaczek.json';
import SprayBottleAnimation from 'assets/animations/zraszacz.json';
import StatueAnimation from 'assets/animations/pomnik.json';
import BarrowAnimation from 'assets/animations/taczka.json';
import AquariumAnimation from 'assets/animations/akwarium.json';
import BirdsAnimation from 'assets/animations/ptaszki.json';
import HandAnimation from 'assets/animations/raczka.json';

import LayerA from 'assets/animations/main/A.json';
import LayerB from 'assets/animations/main/B.json';
import LayerD from 'assets/animations/main/D.json';

import tabletJoined from "assets/animations/main-tablet/joined.json";
import mobileJoined from "assets/animations/mobile-joined.json"

const nameToAnimation = {
  worm: WormAnimation,
  sprayBottle: SprayBottleAnimation,
  statue: StatueAnimation,
  barrow: BarrowAnimation,
  aquarium: AquariumAnimation,
  birds: BirdsAnimation,
  hand: HandAnimation,

  layerA: LayerA,
  layerB: LayerB,
  layerD: LayerD,

  tablet: tabletJoined,
  mobile: mobileJoined,
}

let options = {
  root: null,
  rootMargin: '20px',
  threshold: 0.1
}

const callbackMap = {}
const callback = (entries, observer) => {
  entries.forEach(entry => {
    const anim = entry.target.getAttribute('title')
    const callback = callbackMap[anim]
    if (callback) {
      callback(entry.isIntersecting)
    }
  })
}
let observer = new IntersectionObserver(callback, options)

function getCallback (animationName, setStopped) {
  return isIntersecting => {
    setStopped(!isIntersecting)
  }
}

const LottieAnimation = ({
  animationName,
  className,
  aspectRatio = 'xMidYMid meet',
  ...rest
}) => {
  const ref = useRef()
  const [stopped, setStopped] = useState(false)

  const setRef = useCallback(node => {
    if (ref.current && ref.current.el) {
      observer.unobserve(ref.current.el)
    }

    if (node) {
      const el = node.el
      callbackMap[animationName] = getCallback(animationName, setStopped)
      observer.observe(el)
    }

    ref.current = node
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: nameToAnimation[animationName],
    rendererSettings: {
      preserveAspectRatio: aspectRatio
    }
  }

  return (
    <Lottie
      ref={setRef}
      isStopped={false}
      isPaused={stopped}
      options={defaultOptions}
      title={`${animationName}`}
      {...rest}
    />
  )
}

export default LottieAnimation
