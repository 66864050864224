import React from "react";

import DefaultForm, {SERVICES, PACKAGES} from "./DefaultForm";

export default class PremiumPackageForm extends DefaultForm {
  constructor(props)  {
    super({
      ...props,
      whichServiceForm: false,
      whichOneOffForm: true,
      whichService: SERVICES.package,
      whichPackage: PACKAGES.sky,
    })
  }
}