import React, { Component } from "react";
import GrassGoLogo from "assets/images/grassgo-logo.jpg";
import GardenersGuideLogo from "assets/images/the-gardeners-guild-logo.jpg";
import SepaLogo from "assets/images/sepa-logo.jpg";
import InstaIcon from "assets/images/social-insta.svg";
import TwitterIcon from "assets/images/social-twitter.svg";
import FacebookIcon from "assets/images/social-fb.svg";
import PinterestIcon from "assets/images/social-pinterest.svg";

export default class Footer extends Component {
  render() {
    return (
      <footer className="container-fluid row d-flex justify-items-center pt-5 mx-auto text-center">
        <div className="col-12 col-lg-4 p-2 p-lg-0">
          <a href="#">
            <img
              src={GrassGoLogo}
              alt="grassgo logo"
              style={{ width: "200px" }}
              onClick={this.props.goToTop}
            />
          </a>
        </div>
        <div className="col-lg-1 p-0"></div>
        <div className="col-12 col-lg-2 p-2 p-lg-0 text-lg-left">
          <div>
             0141 404 0434 <br></br>
            info@grassgo.co.uk
          </div>

          <div className="d-flex justify-content-center justify-content-lg-start mt-3">
            <a
              href="https://www.instagram.com/grassgo_gardeners/"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img src={InstaIcon} style={{ width: "25px" }} alt="instagram" />
            </a>
            <a
              href="https://twitter.com/GrassGo_Grdnrs"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                src={TwitterIcon}
                style={{ width: "25px" }}
                alt="instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/grassgoltd"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                src={FacebookIcon}
                style={{ width: "25px" }}
                alt="instagram"
              />
            </a>
            <a
              href="https://www.pinterest.co.uk/grassgo_gardeners"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                src={PinterestIcon}
                style={{ width: "25px" }}
                alt="instagram"
              />
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-2 p-2 p-lg-0 text-lg-left">
          Hillington Business Centre <br></br>
          15-17 Nasmyth Road South,<br></br>
          G52 4RE, Glasgow<br></br>
          <p></p>
          <a
            href="https://goo.gl/maps/vXis1i8HcSGy6cjP7"
            target="_blank"
          >
            <div>View on Google Maps <span>&#62;</span></div>
          </a>
          <a href="/blog/">Visit our blog <span>&#62;</span></a>
        </div>
        <div className="col-12 col-lg-3 p-2 p-lg-0">
          <a
            href="TermsAndConditionsGrassgo.pdf"
            target="_blank"
          >
            <div>Terms and Conditions</div>
          </a>
          <div className="d-flex justify-content-center mt-2">
            <a href="https://www.thegardenersguild.co.uk/" target="_blank">
              <img
                src={GardenersGuideLogo}
                style={{ width: "70px", height: "45px" }}
                alt="The Gardeners Guild Logo"
              />
            </a>
            <a href="https://www.sepa.org.uk/" target="_blank">
              <img
                src={SepaLogo}
                style={{ width: "70px", height: "45px", marginLeft: "5px" }}
                alt="SEPA Logo"
              />
            </a>
          </div>
        </div>

        <div className="copyright align-self-end col-12 pt-1 pb-1">
          © 2022 Grassgo gardeners
        </div>
      </footer>
    );
  }
}
