import React, { Component } from "react";
import { Button } from "react-bootstrap";
import StandardPackageForm from "../Form/StandardPackageForm";
import { GreenButton } from "../Buttons";
import LottieAnimation from "components/Animations/animations";

export default class SeaStandardPackage extends Component {
  constructor() {
    super();

    this.state = {
      hidden: true,
      form: false,
    };

    this.contentRef = React.createRef();

    this.hideShowContent = this.hideShowContent.bind(this);
    this.hideShowForm = this.hideShowForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hidden != this.state.hidden && this.state.hidden === false) {
      if (this.contentRef.current) {
        this.contentRef.current.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
        this.setState({
          form: false,
        })
      }
    }
  }

  hideShowContent() {
    this.setState({
      hidden: !this.state.hidden,
    });
  }

  hideShowForm() {
    this.setState({
      form: !this.state.form,
    });
  }

  render() {
    return (
      <>
        <section
          id="standard"
          className="d-flex sea-standard-package flex-column align-content-center justify-content-center py-5"
        >
          <div className="row d-flex flex-column flex-lg-row justify-items-center">
            <div className="col-12 col-lg-6 text-center">
              <LottieAnimation animationName={"aquarium"} />
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column sky-text justify-content-center">
              <h1>Sea</h1>
              <h3>Standard package</h3>
              <p>
                Everything you need to keep your garden looking tip-top. Included
                are all your essential services, from grass cutting to hedge
                trimming. Naturally, this package can be adapted to fit your
                garden’s specific needs. For example, you can add additional
                services, such as Japanese knotweed removal, or remove other
                services, like lawn mowing, if you have an artificial lawn. The
                Standard package is recommended to people with smaller gardens
                as all general gardening requirements are included as part of
                the service.
              </p>
              <GreenButton
                title={this.state.hidden?  'see more' : 'show less'}
                onClick={this.hideShowContent}
                className={`align-self-center align-self-lg-end`}
              />
            </div>

            <div
              ref={this.contentRef}
              className={
                this.state.hidden ? "d-none" : "col-12 d-flex flex-column"
              }
            >
              <div className="row sea-list">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 d-md-flex pl-2">
                  <table>
                    <tbody>
                      <tr>
                        <td>monitoring gardens</td>
                        <td>pruning</td>
                      </tr>
                      <tr>
                        <td>grass cutting</td>
                        <td>hedge and shrub trimming</td>
                      </tr>
                      <tr>
                        <td>edging of lawns</td>
                        <td>leaf removal</td>
                      </tr>
                      <tr>
                        <td>weeding / hoeing</td>
                        <td>deadheading</td>
                      </tr>
                      <tr>
                        <td>general clearance of beds</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-12 col-lg-6 d-flex flex-column flex-lg-row">
                  <p className="col-12 col-lg-6 date-p small-text pl-0">
                    1 visit, per fortnight during the vegetation season (March –
                    November)<br></br>1 visit per 4 weeks in winter season
                    (December – February)
                  </p>
                  <div className="col-12 col-lg-6 text-center">
                    <Button
                      // onClick={this.hideShowForm}
                      onClick={this.props.goToContactSection}
                      className="book-now-btn book-now-up"
                    >
                      book now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <StandardPackageForm hide={!this.state.form} />
        </div>
      </>
    );
  }
}
